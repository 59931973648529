// Notifications -- based on 1.4 style guilde
// Style guide --> https://sonyplaystation.invisionapp.com/d/main#/console/12220813/317237050/preview

.notification {
    height: 60px;
    font-size: 16px;
    font-weight: 500;
    line-height: 60px;
    text-align: left; 
    color: #fff;
    margin-bottom: 0px;
}
.notification-success {
    background-color: #2d9e94;
}
.notification-info {
    background-color: #006db4;
}
.notification-warning {
    background-color: #bc8824;
}
.notification-error {
    background-color: #c54748;
}

.notification-icon {
    position: absolute;
    left: 22.51px; 
}

.notification-message {
    position: absolute;
    left: 75px; 
}

i {
   &.notification-success-icon {
      display: inline-block;
      height:16px;
      width: 18px;
      margin-bottom: -1px;
   }
   &.notification-info-icon {
      display: inline-block;
      height:19px;
      width: 10px;
      margin-bottom: -2px;
      margin-left: 4px;
   }
   &.notification-warning-icon {
      display: inline-block;
      height:18px;
      width: 20px;
      margin-bottom: -3px;
   }
   &.notification-error-icon {
      display: inline-block;
      height:20px;
      width: 20px;
      margin-bottom: -4px;
   }
}

.notification-action {
    border: 1px solid #fff;
    border-radius: 2.5px;
    cursor: pointer;
    position: absolute;
    right: 65px; 
    top: 15px;  // 60 - 30 / 2
    min-width: 100px;
    height: 30px;
    text-align: center;
    line-height: normal !important;
    padding: 2px;
}

.notification-dismiss {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    opacity: 0.3;
    right: 28px;
    position: absolute;
}
