@sie-input-border-radius: 2.5px;
@sie-input-border-color: #d2d2d2;
@sie-input-background-color: #ffffff;
@sie-input-disabled-background-color: @gray-lighter;
@sie-valid-color: #00A394;
@sie-error-color: #D92F2F;
@sie-active-color: #67CAFF;
@sie-accessory-text-color: #767676;

span {
  &.input-message {
    font-size: 12px;
    color: #999999;
  }
  &.invalid-input-message {
    font-size: 12px;
    color: @error-text-color;
  }
  &.sie-message {
    font-size: 12px;
    color: @sie-accessory-text-color;
  }
}

input.form-control{
  height: 40px;
  font-size: 14px;
  // width: 100% !important;
  border-radius: @sie-input-border-radius;
  border-color: @sie-input-border-color;
  box-shadow: none;
  &.input-sm{
    height: 30px;
  }
  &.input-lg{
    font-size: 16px;
    height: 50px;
  }
  &:focus,
  &:active{
    border-color: @sie-active-color !important;
  }
  &:disabled{
    border: none;
    color: @sie-btn-link-disabled-color;
    background-color: @sie-input-disabled-background-color !important;
  }
}

label{
  font-size: 14px;
  font-weight: normal;
  margin-top: 5px;
  &.disabled,
  &:disabled{
    color:  @sie-btn-link-disabled-color;
  }
  &.required,
  &:required{
    &:after{
      content: "\2022";
      color: @sie-error-color;
      font-size: 12px;
      margin: 5px;
    }
  }
}

p{
  &.contextual-help {
      color: #9B9B9B;
      font-size: 12px;
  }
}

.form-group{
  text-align: left;
  &.form-inline{
    display: flex;
    label{
      margin-right: 20px;
      text-align: right;
    }
  }
}

.sie-has-error {
  .form-control-validation(@sie-error-color; @sie-error-color; inherit);
}

.sie-has-error p.text-danger{
  font-size: 12px;
  margin-top: -0.2em;
}

.sie-has-valid {
  .form-control-validation(@sie-valid-color; @sie-valid-color; inherit);
}

.input-group-addon{
  font-size: 12px;
  background-color: inherit;
}

input.floating-label {
   height: 40px;

   &:focus + label.floating-label,
   &.has-value + label.floating-label
   {
     font-size: 9px;
     margin-top: -81px;
   }
}

label.floating-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 9px;
    vertical-align: middle;
    transition: 0.1s all ease-in-out;
    font-weight: normal;
    /*font-size: 14px;*/
    /*font-family: SSTPro_Light;*/
    margin-top: -55px;
    margin-left: 13px;
    color: #999999;
    pointer-events: none;
}

input.floating-label.max + label.floating-label {
    margin-top: -130px;
}

input[type=radio] ~ div > p.text-danger {
  margin-top: -2px;
  margin-bottom: 5px;
  font-size: 12px;
}

input[type=radio] ~ div {
  margin-top: -8px;
}

div.checkbox p.text-danger {
  margin-bottom: 5px;
  font-size: 12px;
  margin-top: -0.2em;
}

div.checkbox div {
  margin-top: -12px;
  margin-bottom: 5px;
}

// used by the sie-tooltip
span {
  &.question-icon {
    position: relative;
    top: 2px;
    margin-left: 3px;
    margin-right: 3px;
    background: url('../svg/question-circle-solid.svg');
    display: inline-block;
    text-indent: -9999px;
    height: 12px;
    width: 12px;
  }
}

label.sie-optional::after {
  content: " (optional)";
  font-size: .8em;
  //font-style: italic;
}

// css implementation of tooltip (as data element...see css docs)
[sie-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

//bring tooltip a little lower for textfields
div [sie-tooltip] > span.question-icon {
  margin-bottom: -2px;
}

// Hide the tooltip content by default
[sie-tooltip]:before, [sie-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[sie-tooltip]:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 10px;
  width: 160px;
  border-radius: 3px;
  background-color: #f2f2f2;
  color: #363636 ;
  content: attr(sie-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
}

// Triangle hack to make tooltip look like a speech bubble
[sie-tooltip]:after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #f2f2f2;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

// Show tooltip content on hover
[sie-tooltip]:hover:before, [sie-tooltip]:hover:after {
  visibility: visible;
  transition: opacity .25s ease-in-out;
  opacity: 1;
}
