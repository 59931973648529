//
// Progress bars
// --------------------------------------------------


// Bar animations
// -------------------------

// WebKit
@-webkit-keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}

// Spec and IE10+
@keyframes progress-bar-stripes {
  from  { background-position: 40px 0; }
  to    { background-position: 0 0; }
}


// Bar itself
// -------------------------

// Outer container
.progress {
  overflow: hidden;
  height: 10px;
  margin-bottom: 0;
  background-color: @progress-bg;
  border-radius: @progress-border-radius;
  // .box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
}

// Bar of progress
.progress-bar {
  float: left;
  width: 0%;
  height: 10px;
  font-size: @font-size-small;
  line-height: @line-height-computed;
  color: @progress-bar-color;
  text-align: center;
  background-color: @progress-bar-bg;
  background: #308CD7;
  border-radius: 10px;
  &.progress-bar-indeterminate {
    animation: progress-bar-indeterminate 1.5s infinite;
  }
  &.demo-animation-completed {
    animation: completed 12s forwards;
  }
  &.demo-animation-failed {
    animation: failed 13s forwards;
  }
  // .box-shadow(inset 0 -1px 0 rgba(0,0,0,.15));
  .transition(width .6s ease);
}

@keyframes progress-bar-indeterminate {
  0% {
    margin-left: -100%;
    // width: 0;
  }
  30% {
    // margin-left: 0;
    // width: 60%;
  }
  90% {
    // margin-left: 70%;
    width: 100%;
  }

  100% {
    margin-left: 100%;
    width: 0;
  }
}

@keyframes completed {
  0% {
    width: 0;
    background-color: @progress-bar-bg;
  }
  10% {
    width: 7%;
  }
  20% {
    width: 22%;
  }
  25% {
    width: 23%;
  }
  30% {
    width: 38%;
  }
  40% {
    width: 51%;
  }
  50% {
    width: 60%;
  }
  60% {
    width: 66%;
  }
  70% {
    width: 71%;
  }
  80% {
    width: 80%;
  }
  90% {
    width: 91%;
  }
  95% {
    width: 100%;
    background-color: @progress-bar-bg;
  }
  100% {
    width: 100%;
    background-color: @valid-color;
  }
}
@keyframes failed {
  0% {
    width: 0;
    background-color: @progress-bar-bg;
  }
  10% {
    width: 7%;
  }
  20% {
    width: 22%;
  }
  25% {
    width: 23%;
  }
  30% {
    width: 38%;
  }
  40% {
    width: 51%;
  }
  50% {
    width: 60%;
  }
  54% {
    width: 65%;
    background-color: @progress-bar-bg;
  }
  58% {
    width: 65%;
    background-color: @error-color;
  }
  100% {
    width: 65%;
    background-color: @error-color;
  }
}

// Striped bars
//
// `.progress-striped .progress-bar` is deprecated as of v3.2.0 in favor of the
// `.progress-bar-striped` class, which you just add to an existing
// `.progress-bar`.
.progress-striped .progress-bar,
.progress-bar-striped {
  #gradient > .striped();
  background-size: 40px 40px;
}

// Call animation for the active one
//
// `.progress.active .progress-bar` is deprecated as of v3.2.0 in favor of the
// `.progress-bar.active` approach.
.progress.active .progress-bar,
.progress-bar.active {
  .animation(progress-bar-stripes 2s linear infinite);
}


// Variations
// -------------------------

.progress-bar-success {
  .progress-bar-variant(@progress-bar-success-bg);
}

.progress-bar-info {
  .progress-bar-variant(@progress-bar-info-bg);
}

.progress-bar-warning {
  .progress-bar-variant(@progress-bar-warning-bg);
}

.progress-bar-danger {
  .progress-bar-variant(@progress-bar-danger-bg);
}
