
.sie-control__control {
   background-color: #FFFFFF !important;
   border-color: #D2D2D2 !important;
}

.sie-control__control--is-focused {
   border-color: #67CAFF !important;
   box-shadow: 0 0 0 1px transparent !important;
   &.has-error {
    height: 38px;
    box-shadow: transparent !important;
   }
}

.sie-control__control--is-focused.menu-open {
  &:not(.has_error) {
    box-shadow: inset 0px 1px 0px 0px #67CAFF !important;
  }
  border-top: 0px !important;
  border-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  &:not(.multi-enabled) {
    height: 38px;
    .sie-control__value-container {
      & > div:last-child {
        width: 100%;
      }
    }
  }
  &.searchable-enabled {
    &:not(.multi-enabled) {
      height: inherit;
      width: 100%;
      position: relative;
      &:not(.has_error) {
        box-shadow: inset 0px 1px 0px 0px #67CAFF !important;
      }
      border-top: 0px !important;
      border-bottom: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      .sie-control__placeholder {
        top: 19px !important;
      }
      .sie-control__value-container {
        &.sie-control__value-container--has-value {
          .sie-control__single-value {
            top: 19px !important;
          }
        }
      }
      .sie-control__indicators {
        position: absolute;
        top: 0;
        right: 0;
        height: 37px;
      }
      .sie-control__input {
        position: relative;
        border-radius: 3.5px;
        border: 1px solid #67CAFF;
        margin-top: 40px;
        width: 100%;
        height: 30px;
        padding-top: 4px;
        padding-left: 4px;
      }
    }
  }
  &.multi-enabled {
    i {
      &.glyphicon-search {
        display: none !important;
      }
    }
    // height: inherit !important;
    .sie-control__value-container {
      &.sie-control__value-container--has-value {
        .sie-control__single-value {
          top: 19px !important;
        }
      }
    }
  }
  &.has-error {
    box-shadow: inset 0px 1px 0px 0px #D92F2F !important;
  }
}

.sie-control__control--is-disabled {
   cursor: not-allowed;
   border: 1px solid #EEEEEE !important;
   border-radius: 3.5px !important;
   background-color: #EEEEEE !important;
}

.sie-control__menu {
   z-index: 9999 !important;
   margin-top: 0px !important;
   border-top-left-radius: 0px !important;
   border-top-right-radius: 0px !important;
   box-shadow: 0 -1px 0 0 inset #67CAFF !important;
   border-left: 1px #67CAFF solid !important;
   border-right: 1px #67CAFF solid !important;
   border-top: 0px !important;
}

.sie-control__option {
   background-color: #FFFFFF;
   color: #585858;
   font-family: 'SST Pro Roman';
}

.sie-control__option--is-focused {
   background-color: #F5F5F5 !important;
}

.sie-control__control--is-focused .sie-control__dropdown-indicator {
   color: #67CAFF !important;
}

.sie-control__indicator-separator {
   display:none !important;
}

.sie-control__option--is-selected {
   background-color: #F5F5F5 !important;
   box-shadow: inset 1px 0px 0px 0px #67CAFF;
   border-right: 1px solid #67CAFF;
   color: #585858 !important;
}

.sie-control__placeholder {
  color: #999999 !important;
}

.sie-control__single-value {
   color: #585858 !important;
   font-family: 'SST Pro Roman';
}

.sie-control__multi-value {
  border-radius: 3.5px !important;
  background-color: #DEDEDE !important;
}

.sie-control__multi-value__label{
  font-family: 'SST Pro Roman';
  color: #363636 !important;
  font-size: 14px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

/* error styles */
.sie-control__control.has_error {
   border-color: #D92F2F !important;
}

.sie-control__control--is-focused.has_error {
  box-shadow: 0 !important;
}

.sie-control__dropdown-indicator.has_error {
   color: #D92F2F;
}

.sie-control__control.has_error {
   border-color: #D92F2F !important;
}
.sie-control__control.has_error.menu-open {
   border-color: #D92F2F !important;
   box-shadow: inset 0px 1px 0px 0px #D92F2F !important;
}

.sie-control__menu.has_error {
   border-color: #D92F2F !important;
   box-shadow: inset 0 -1px 0 0 #D92F2F !important;
}

.sie-control__dropdown-indicator.has_error {
   color: #D92F2F !important;
}

div.sie-select div.sie-status {
   margin-top: 5px;
}

div.sie-select p.text-danger {
  font-size: 12px;
  margin-top: -0.2em;
}

div.sie-select div.sie-message {
  font-size: 12px;
  color: #767676;
  padding-bottom: 3px;
  margin-top: -6px;
}

.sie-control__value-container--is-multi {
       white-space: nowrap;
       overflow: hidden;
}

.sie-control__multi-value__label {
   text-overflow:  ellipsis;
}

div.sie-select [sie-tooltip] > span.question-icon {
  margin-bottom: -1px;
}
