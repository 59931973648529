
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* variable definition for the file, should probably be moved into sie-variables */
@sie-loading-speed: 1s;
@sie-loading-animation-name: spin;
@sie-loader-width-mobile:32px;
@sie-loader-width-tablet:48px;
@sie-loader-width-small:20px;
@sie-loader-width-button:24px;
@sie-loader-easing-function: cubic-bezier(.38,.27,.48,.87);

/* mixin to reduce duplication, should probably move it into the mixins folder */
.sie-loader(@loader-width){
  @loader-border-width: ceil((@loader-width / 8));
  border: @loader-border-width solid #DEDEDE;
  border-top: @loader-border-width solid #00A2FF; /* Blue */
  border-radius: 50%;
  width: @loader-width;
  height: @loader-width;
  animation: @sie-loading-animation-name @sie-loading-speed @sie-loader-easing-function infinite;
}

/*use '.sie-' prefix for class definition added by us to make them opt-in and not conflict with any existing class definitions */

.sie-loader-wrapper{
  
  text-align:center;

  > * {
      margin: 0 auto;
  }

  > .sie-loader-inline{
    display:inline-block;
  }

  > .sie-loader-inline ~ .sie-loader-message{
    vertical-align: top;
    display:inline;
  }
}

.sie-loader {
    .sie-loader(@sie-loader-width-mobile);

    &.sie-loader-small {
        .sie-loader(@sie-loader-width-small);
    }
}



.sie-determinate-loader {
  .determinate-loader-wrapper {
    position: relative;
    margin: 0 auto;
    width:60px;
  }
  span {
    &.determinate-loader {
      position: relative;
      display: inline-block;
      left: 50%;
      transform: translateX(-50%);
      margin-left: auto;
      margin-right: auto;
      height: 60px;
      width: 60px;
      background: url('../svg/determinate-loader.svg');
    }
  }
  label {
    &.determinate-loader-label {
      position: absolute;
      top: 42%;
      left: 50%;
      z-index: 999;
      transform: translate(-50%, -50%);
      &.complete {
        margin-left: 1px;
      }
    }
  }
}

.sie-loader-message{
  margin: 8px 0px;
  text-align: center;
  transition: color 0.2s ease;
  &.complete {
    visibility: hidden;
  }
}

/*add loading class for .btn lets*/
.btn {
  &.btn-primary {
    &.sie-loading {
      position:relative;
      color:transparent !important;
      opacity:.5;
      &:hover,
      &:active,
      &:focus {
        cursor: auto;
        color:transparent !important;
        opacity:.5;
        background-color: @sie-btn-primary-color;
        border-color: @sie-btn-primary-color;
        outline: none;
      }
      &::after{
        .sie-loader(@sie-loader-width-button);
        position: absolute;
        content: '';
        top:50%;
        left:50%;
        opacity:1;
        margin: -12px 0 0 -12px; //margins should change is border width for button is changed
      }
    }
  }
  &.btn-default {
    &.sie-loading {
      position:relative;
      color:transparent !important;
      opacity:.5;
      &:hover,
      &:active,
      &:focus {
        cursor: auto;
        color:transparent !important;
        opacity:.5;
        background-color: white;
        border-color: #ccc;
        outline: none;
      }
      &::after{
        .sie-loader(@sie-loader-width-button);
        position: absolute;
        content: '';
        top:50%;
        left:50%;
        opacity:1;
        margin: -12px 0 0 -12px; //margins should change is border width for button is changed
      }
    }
  }
  &.sie-loading {
    position:relative;
    color:transparent !important;
    opacity:.5;
    &:hover,
    &:active,
    &:focus {
      cursor: auto;
      color:transparent !important;
      opacity:.5;
      background-color: none;
      border-color: none;
      outline: none;
      box-shadow: none;
    }
    &::after{
      .sie-loader(@sie-loader-width-button);
      position: absolute;
      content: '';
      top:50%;
      left:50%;
      opacity:1;
      margin: -12px 0 0 -12px; //margins should change is border width for button is changed
    }
  }
}

@media(min-width:768px){
  .sie-loader {
      .sie-loader(@sie-loader-width-tablet);
  }

  .sie-loader-message{
    margin: 10px 0px;
  }
}
