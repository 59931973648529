
// Import SST
@font-face {
  font-family: 'SST Bold';
  src: url('@{text-font-path}@{text-sst-prefix}Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'SST BoldItalic';
  src: url('@{text-font-path}@{text-sst-prefix}BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Condensed';
  src: url('@{text-font-path}@{text-sst-prefix}Condensed.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Condensed Bold';
  src: url('@{text-font-path}@{text-sst-prefix}CondensedBd.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Condensed Medium';
  src: url('@{text-font-path}@{text-sst-prefix}CondensedMd.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Heavy';
  src: url('@{text-font-path}@{text-sst-prefix}Heavy.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Heavy Italic';
  src: url('@{text-font-path}@{text-sst-prefix}HeavyItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Italic';
  src: url('@{text-font-path}@{text-sst-prefix}Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Light';
  src: url('@{text-font-path}@{text-sst-prefix}Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Light Italic';
  src: url('@{text-font-path}@{text-sst-prefix}LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Medium';
  src: url('@{text-font-path}@{text-sst-prefix}Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Medium Italic';
  src: url('@{text-font-path}@{text-sst-prefix}MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'SST Roman';
  src: url('@{text-font-path}@{text-sst-prefix}Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'SST UltraLight';
  src: url('@{text-font-path}@{text-sst-prefix}UltraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'SST UltraLightItalic';
  src: url('@{text-font-path}@{text-sst-prefix}UltraLightItalic.ttf') format('truetype');
}

// Import SST-Pro
@font-face {
  font-family: 'SST Pro Bold';
  src: url('@{text-font-path}@{text-sstpro-prefix}Bold.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro BoldItalic';
  src: url('@{text-font-path}@{text-sstpro-prefix}BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Condensed';
  src: url('@{text-font-path}@{text-sstpro-prefix}Condensed.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Condensed Bold';
  src: url('@{text-font-path}@{text-sstpro-prefix}CondensedBd.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Heavy';
  src: url('@{text-font-path}@{text-sstpro-prefix}Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Heavy Italic';
  src: url('@{text-font-path}@{text-sstpro-prefix}HeavyItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Italic';
  src: url('@{text-font-path}@{text-sstpro-prefix}Italic.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Light';
  src: url('@{text-font-path}@{text-sstpro-prefix}Light.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Light Italic';
  src: url('@{text-font-path}@{text-sstpro-prefix}LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Medium';
  src: url('@{text-font-path}@{text-sstpro-prefix}Medium.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Medium Italic';
  src: url('@{text-font-path}@{text-sstpro-prefix}MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Roman';
  src: url('@{text-font-path}@{text-sstpro-prefix}Roman.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Ultra Light';
  src: url('@{text-font-path}@{text-sstpro-prefix}UltraLight.otf') format('opentype');
}

@font-face {
  font-family: 'SST Pro Ultra Light Italic';
  src: url('@{text-font-path}@{text-sstpro-prefix}UltraLightItalic.otf') format('opentype');
}
